(function() {
    'use strict';

    angular.module('ionicInfiniteScroll', [
        'ionic',
        'uabDefaultVariable'
    ]);
})();
(function() {
    'use strict';

    angular.module('ionicInfiniteScroll').controller('IonicInfiniteScrollController', IonicInfiniteScrollController);

    IonicInfiniteScrollController.$inject = [
        'DefaultVariableService',
        '$ionicScrollDelegate',
        '$q',
        '$scope'
    ];

    function IonicInfiniteScrollController(
        DefaultVariableService,
        $ionicScrollDelegate,
        $q,
        $scope
    ) {
        var IonicInfiniteScrollController = this;

        $scope.$watch(
            'canLoadMore',
            function(canLoadMore) {
                IonicInfiniteScrollController.canLoadMore = DefaultVariableService.get(
                    canLoadMore,
                    false
                );
            }.bind(IonicInfiniteScrollController)
        );

        $scope.$watch(
            'immediateCheck',
            function(immediateCheck) {
                IonicInfiniteScrollController.immediateCheck = DefaultVariableService.get(
                    immediateCheck,
                    false
                );
            }.bind(IonicInfiniteScrollController)
        );

        $scope.$watch(
            'isLoading',
            function(isLoading) {
                IonicInfiniteScrollController.isLoading = DefaultVariableService.get(
                    isLoading,
                    false
                );
            }.bind(IonicInfiniteScrollController)
        );

        $scope.$watch(
            'loadMore',
            function(onLoadMore) {
                IonicInfiniteScrollController.onLoadMore = DefaultVariableService.getPromise(
                    onLoadMore
                );
            }.bind(IonicInfiniteScrollController)
        );

        IonicInfiniteScrollController.loadMore = forceRefresh;
        function forceRefresh() {
            return IonicInfiniteScrollController.onLoadMore().finally(
                function() {
                    $scope.$broadcast('scroll.infiniteScrollComplete');

                    $ionicScrollDelegate.resize();
                }
            );
        }

        IonicInfiniteScrollController.reset = reset;
        function reset() {

        }

        IonicInfiniteScrollController.init = init;
        function init() {
            IonicInfiniteScrollController.reset();
        }

        IonicInfiniteScrollController.init();
    }
})();
(function() {
    'use strict';

    angular.module('ionicInfiniteScroll').directive('ionicInfiniteScroll', ionicInfiniteScroll);

    function ionicInfiniteScroll() {
        return {
            controller:   'IonicInfiniteScrollController',
            controllerAs: 'ctrl',
            replace:      true,
            restrict:     'E',
            scope: {
                canLoadMore:    '=',
                immediateCheck: '=',
                isLoading:      '=',
                loadMore:       '='
            },
            template:'<span><ion-infinite-scroll data-ng-if="ctrl.canLoadMore && !ctrl.isLoading" immediate-check="ctrl.immediateCheck" on-infinite="ctrl.loadMore()"></ion-infinite-scroll><span data-ng-if="ctrl.isLoading"><ion-spinner class="centerSpinner"></ion-spinner></span></span>'
        };
    }
})();